<template>
  <div class="main">
    <button @click="info">info</button>

    <h1>res: {{ res }}</h1>
    <h1>err: {{ err }}</h1>
    <h3>logger: {{ logger }}</h3>
  </div>
</template>

<script>
const axios = require("axios");

import { Plugins } from "@capacitor/core";
export default {
  name: "HelloWorld",
  data() {
    return {
      res: null,
      err: null,
      logger: null,
    };
  },
  methods: {
    info: function () {
      // this.axios.get("http://192.168.1.1/osc/info", {header: {'Access-Control-Allow-Origin': '*'}}).then((response) => {
      //   console.log(response.data);
      //   console.log('asdasd');
      // });
      console.log = function (message) {
        if (typeof message == "object") {
          this.logger =
            (JSON && JSON.stringify ? JSON.stringify(message) : message) +
            "<br />";
        } else {
          this.logger = message + "<br />";
        }
      };

      axios
        .get("http://192.168.1.1/osc/info")
        .then((response) => {
          // handle success
          console.log("give me res");
          console.log(response);
          this.res = response;
        })
        .catch((error) => {
          // handle error
          console.log(error);
          this.err = error;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
